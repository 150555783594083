import React from "react"
import { Form, Input, Icon, Button } from "antd"

import { signIn } from "../api/account"

function SignIn(props) {
  const { getFieldDecorator } = props.form

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        signIn(values.username, values.password, () => {
          props.form.resetFields()
          props.successCallback()
        })
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "请输入用户名" }],
          })(
            <Input
              prefix={<Icon type="user" />}
              placeholder="用户名"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "请输入密码" }],
          })(
            <Input
              prefix={<Icon type="lock" />}
              type="password"
              placeholder="密码"
              allowClear
            />
          )}
        </Form.Item>
        <Button htmlType="submit">确定</Button>
      </div>
    </Form>
  )
}

export default Form.create()(SignIn)