import { axiosBackEnd } from "../utils/helper"
import { USERNAME, AVATAR, USERID, TOKEN } from "../utils/constant"
import { message } from "antd"

const option = {
  headers: {
    "Content-Type": "application/json",
  },
}

export function handleThirdPartyLogin(callback) {
  const url = window.location.search
  if (url.indexOf("?") !== -1) {
    let str = url.substr(1)
    let params = str.split("&")
    const code = params[0].split("=")[1]
    const state = params[0].split("=")[1]
    axiosBackEnd
      .post(
        "/oauth/callback/github",
        {
          code: code,
          state: state,
        },
        option
      )
      .then(res => {
        const data = res.data.data
        document.cookie = `TOKEN=${data.token};path=/`
        window.localStorage.setItem(USERNAME, data.username)
        window.localStorage.setItem(AVATAR, data.avatar)
        window.localStorage.setItem(USERID, data.userId)
        callback()
      })
      .catch(err => {})
  }
}

export function signIn(username, password, callback) {
  axiosBackEnd
    .post(
      "/signIn",
      {
        username: username,
        password: password,
      },
      option
    )
    .then(res => {
      if (res.data.code === 0) {
        const data = res.data.data
        if (data.token !== null) {
          window.localStorage.setItem(TOKEN, data.token)
        }
        if (data.username !== null) {
          window.localStorage.setItem(USERNAME, data.username)
        }
        if (data.avatar !== null) {
          window.localStorage.setItem(AVATAR, data.avatar)
        }
        if (data.userId !== null) {
          window.localStorage.setItem(USERID, data.userId)
        }
        callback()
      } else {
        message.error(res.data.message)
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export function signUp(username, password, callback) {
  axiosBackEnd
    .post(
      "/register",
      {
        username: username,
        password: password,
      },
      option
    )
    .then(res => {
      message.success("注册成功")
      callback()
    })
    .catch(err => {
      console.error(err.response)
    })
}
