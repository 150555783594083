import React from "react"
import { Form, Input, Icon, Button } from "antd"

import { signUp } from "../api/account"

function SignUp(props) {
  const { form, successCallback } = props
  const { getFieldDecorator } = form

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        signUp(values["username-reg"], values["password-reg"], () => {
          form.resetFields()
          successCallback()
        })
      }
    })
  }

  function validatePassword(rule, value, callback) {
    const compareVal = form.getFieldValue("password-reg-repeat")
    if (!value) {
      callback("请输入密码")
    } else if (compareVal && compareVal !== value) {
      callback("两次输入的密码不一致")
    } else {
      callback()
    }
  }

  function validateConfirmPassword(rule, value, callback) {
    const compareVal = form.getFieldValue("password-reg")
    if (!value) {
      callback("请再次输入密码")
    } else if (compareVal && compareVal !== value) {
      callback("两次输入的密码不一致")
    } else {
      callback()
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <Form.Item>
          {getFieldDecorator("username-reg", {
            rules: [{ required: true, message: "请输入用户名" }],
          })(
            <Input
              prefix={<Icon type="user" />}
              placeholder="用户名"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password-reg", {
            rules: [{ validator: validatePassword }],
          })(
            <Input
              prefix={<Icon type="lock" />}
              type="password"
              placeholder="密码"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password-reg-repeat", {
            rules: [
              {
                validator: validateConfirmPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" />}
              type="password"
              placeholder="确认密码"
              allowClear
            />
          )}
        </Form.Item>
        <Button htmlType="submit">确定</Button>
      </div>
    </Form>
  )
}

export default Form.create()(SignUp)