import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Tabs } from "antd"
import { Helmet } from "react-helmet"

import userStyles from "./user.module.css"
import "./user.css"
import SignIn from "../components/SignIn"
import SignUp from "../components/SignUp"
import { handleThirdPartyLogin } from "../api/account"
import { hasCookie } from "../utils/helper"

import github from "../icons/github.svg"

const { TabPane } = Tabs

// FIXME: account
function Login(props) {
  const [activeTab, setActiveTab] = useState("login")

  useEffect(() => {
    if (hasCookie("TOKEN")) {
      // 已经登陆
      // window.history.back()
    } else {
      handleThirdPartyLogin(() => {
        navigate("/")
      })
    }
  }, [])

  function handleTabClick(val) {
    setActiveTab(val)
  }

  return (
    <div className={userStyles.user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{activeTab === "login" ? "登陆" : "注册"}</title>
      </Helmet>
      <div className="login-dialog">
        <div className="form-container">
          <Tabs
            defaultActiveKey="login"
            activeKey={activeTab}
            className="login-tab"
            onTabClick={handleTabClick}
          >
            <TabPane tab="登陆" key="login">
              <SignIn
                successCallback={() => navigate("/")}
              />
            </TabPane>
            <TabPane tab="注册" key="register">
              <SignUp successCallback={() => setActiveTab("login")} />
            </TabPane>
          </Tabs>
          <div className="form-footer">
            <div className="third-party-login">
              <a href="https://api.mdnice.com/oauth/render/github">
                <img src={github} alt="login-github" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login